/* eslint-disable quotes */
import { useEffect, useState } from "react";
import { NavLink, useParams } from "react-router-dom";

import formatISO from "date-fns/formatISO";
import getDaysInMonth from "date-fns/getDaysInMonth";
import getHours from "date-fns/getHours";

import { toast } from "react-toastify";
import { api } from "../../services/api";

import { ReactComponent as CalendarIcon } from "../../assets/images/calendar.svg";
import { ReactComponent as CouponIcon } from "../../assets/images/coupon.svg";
import { ReactComponent as GetNinjasIcon } from "../../assets/images/getninjas.svg";
import { ReactComponent as MarkerIcon } from "../../assets/images/marker.svg";

import { ReactComponent as CircleAdd } from "../../assets/images/circleadd.svg";
import { ReactComponent as QuestionIcon } from "../../assets/images/question.svg";

import { ReactComponent as ArrowLeftIcon } from "../../assets/images/arrow-left.svg";
import { ReactComponent as LoadingIcon } from "../../assets/images/loading.svg";

import {
  ICoupon,
  IProduct,
  IServiceType,
  IUserAddress,
  IUserCard,
  ServiceTypeSolicitationQuestionHasValue,
} from "../../types";

import { AddCardModal } from "../../components/AddCardModal";
import { AddressModal } from "../../components/AddressModal";
import { CategoryIcon } from "../../components/CategoryIcon";
import { ClientGetNinjasModal } from "../../components/ClientGetNinjasModal";
import { EditMainCardModal } from "../../components/EditMainCardModal";
import { Hexagon } from "../../components/Hexagon";
import { NotificationModal } from "../../components/NotificationModal";
import { PaymentInformationModal } from "../../components/PaymentInformationModal";
import { SearchProductModal } from "../../components/SearchProductModal";
import { SearchingProvider } from "../../components/SearchingProvider";
import { Select } from "../../components/Select";
import { ServiceQuestionIcon } from "../../components/ServiceQuestionIcon";
import { ServiceSplashScreen } from "../../components/ServiceSplashScreen";
import { IClientSolicitationAccepted } from "../../contexts/AppointmentContext";

import { useAppointments } from "../../hooks/useAppointments";
import { MonthsOptions } from "../../resources/MonthsOptions";

import { SelectedCard } from "./SelectedCard";

import {
  AppointmentDateContainer,
  Container,
  CouponContainer,
  GoBackButton,
  InformationContainer,
  PaymentContainer,
  PaymentQuestion,
  ProductContainer,
  RequestServiceButton,
  StyledForm,
  StyledHeader,
} from "./styles";

interface IUserInfo {
  address_id: string;
  address_street: string;
  address_number: string;
  address_neighborhood: string;
  cards: IUserCard[];
  clientgetninjas_id: string;
  clientgetninjas_name: string;
  clientgetninjas_email: string;
  clientgetninjas_telephone: string;
}

interface IServiceFormData {
  clientgetninjas_id: string;
  clientgetninjas_name: string;
  clientgetninjas_email: string;
  clientgetninjas_telephone: string;
  address_id: string;
  address_street: string;
  address_number: string;
  address_neighborhood: string;
  questions: {
    question_id: string;
    value_id: string;
    value: string;
    answer_price: string;
    free_text: boolean;
    order: number;
    answers: ServiceTypeSolicitationQuestionHasValue[];
    useInput: number;
  }[];
  card_id: string;
  coupon_code: string;
}

const getOptionsFromQuestionAnswer = (
  answers: ServiceTypeSolicitationQuestionHasValue[]
) =>
  answers.map((answer) => ({
    value: answer.id,
    label: answer.value,
  }));

const getAddressOptions = (
  addressId: string,
  addressStreet: string,
  addressNumber: string,
  addressNeighborhood: string
): { value: string; label: string }[] => [
  {
    value: addressId,
    label: `${addressStreet}, ${addressNumber} - ${addressNeighborhood}`,
  },
];

const getClientGetNinjasOptions = (
  cgnId: string,
  cgnName: string,
  cgnEmail: string
): { value: string; label: string }[] => [
  {
    value: cgnId,
    label: cgnName,
  },
];

const formatCurrency = (value: number) =>
  new Intl.NumberFormat("pt-BR", { style: "currency", currency: "BRL" }).format(
    value / 100
  );

const getMonthsDayOptions = (date: Date) => {
  const days = getDaysInMonth(date);
  const options = [];

  for (let i = 1; i <= days; i++) {
    options.push({
      value: `${i}`,
      label: `${i}`,
    });
  }
  return options;
};

const getTimesInDay = (date: Date) => {
  const times = [];

  for (let i = 8; i < 19; i++) {
    times.push({
      value: `${i < 10 ? "0" : ""}${i}:00`,
      label: `${i < 10 ? "0" : ""}${i}:00`,
    });
    times.push({
      value: `${i < 10 ? "0" : ""}${i}:30`,
      label: `${i < 10 ? "0" : ""}${i}:30`,
    });
  }

  return times.slice(0, -1);
};

export default function RequestService() {
  const {
    socket,
    onSolicitationToConfirm,
    onRequestSolicitation,
    requestedSolicitation,
    clearNewAppointment,
    isSearchingProviderOpen,
  } = useAppointments();
  const { serviceTypeId } = useParams<{
    serviceTypeId: string;
  }>();

  const [serviceFormData, setServiceFormData] = useState({
    address_id: "",
    address_street: "",
    address_number: "",
    address_neighborhood: "",
    clientgetninjas_id: "",
    clientgetninjas_name: "",
    clientgetninjas_email: "",
    clientgetninjas_telephone: "",
    card_id: "",
    questions: [],
    coupon_code: 'GETNINJAS2024',
  } as IServiceFormData);
  const [productImage, setProductImage] = useState<{
    url: string;
    file: File | null;
  }>({
    file: null,
    url: "",
  });
  const [product, setProduct] = useState<IProduct>();
  const [appointmentDate, setAppointmentDate] = useState(new Date());
  const [coupon, setCoupon] = useState<{
    data?: ICoupon;
    error: string;
  }>();

  const [serviceType, setServiceType] = useState<IServiceType>();
  const [userInfo, setUserInfo] = useState<IUserInfo | undefined>();

  const [isAddCardModalOpen, setIsAddCardModalOpen] = useState(false);
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const [isClientGetNinjasModalOpen, setIsClientGetNinjasModalOpen] =
    useState(false);
  const [isEditCardModalOpen, setIsEditCardModalOpen] = useState(false);
  const [isPaymentInformationModalOpen, setIsPaymentInformationModalOpen] =
    useState(false);
  const [isSplashOpen, setIsSplashOpen] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [hasEmitted, setHasEmitted] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);

  const [handleSearchProductModal, setHandleSearchProductModal] = useState({
    isOpen: false,
    onClose: () =>
      setHandleSearchProductModal((prevState) => ({
        ...prevState,
        isOpen: false,
      })),
    onConfirm: (productToConfirm: IProduct) => {},
  });
  const [handleNotificationModal, setHandleNotificationModal] = useState({
    isOpen: false,
    onClose: () =>
      setHandleNotificationModal((prevState) => ({
        ...prevState,
        isOpen: false,
      })),
    title: "",
    type: "success" as "success" | "error",
  });

  const selectedCard = userInfo?.cards.find(
    (card) => card.id === serviceFormData.card_id
  );

  const daysOptions = getMonthsDayOptions(appointmentDate);
  const timeOptions = getTimesInDay(appointmentDate);

  const selectedDay = appointmentDate.getDate().toString();
  const selectedMonth = appointmentDate.getMonth().toString();
  const selectedTime = `${
    appointmentDate.getHours() < 10
      ? `0${appointmentDate.getHours()}:${appointmentDate
          .getMinutes()
          .toString()
          .padStart(2, "0")}`
      : `${getHours(appointmentDate)}:${appointmentDate
          .getMinutes()
          .toString()
          .padStart(2, "0")}`
  }`;

  /* const totalPrice = serviceType ? serviceType?.base_price + (
    serviceFormData.questions.reduce((acc, question) => acc + Number(question.answer_price), 0)
  ) : 0; */

  useEffect(() => {
    getServiceType();
  }, [serviceTypeId]);

  useEffect(() => {
    ensureUserInfo();
  }, []);

  useEffect(() => {
    if (isSearchingProviderOpen) {
      const minSeconds = 150;
      const maxSeconds = 180;
      const randomTimeout =
        (minSeconds + Math.random() * (maxSeconds - minSeconds)) * 1000;
      // Salva o ID do timeout para que possa ser cancelado caso algum prestador ja tenha aceitado
      const timeoutId = setTimeout(() => {
        socket?.emit("service_solicitation_response_clt", {
          provider_id: null,
          service_id: requestedSolicitation?.id,
        });
      }, randomTimeout);
      const handleClientSolicitationAccepted = (e: IClientSolicitationAccepted) => {
        // Cancela o timeout se o evento for recebido
        clearTimeout(timeoutId);
        onSolicitationToConfirm(e);
      };
      socket?.on("client_solicitation_accepted", handleClientSolicitationAccepted);
      return () => {
        // Limpa tanto o listener do socket quanto o timeout ao desmontar
        socket?.off("client_solicitation_accepted", handleClientSolicitationAccepted);
        clearTimeout(timeoutId);
      };
    }
    return () => {
      // Limpa o listener do socket ao desmontar
      socket?.off("client_solicitation_accepted");
    };
  }, [isSearchingProviderOpen]);

  /* useEffect(() => {
    socket?.on('client_solicitation_error', (e) => {
      handleSolicitationError(e.message);
    });

    return () => {
      socket?.off('client_solicitation_error');
    };
  }, [isSearchingProviderOpen]); */

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    setIsLoading(true);

    const dataToSubmit = {
      date: formatISO(appointmentDate.setSeconds(0, 0)),
      price: totalPrice,
      product_id: product?.id,
      product_model: product?.model || "",
      address_id: serviceFormData.address_id,
      payment_data: {
        payment_type_id: 1,
        card_id: serviceFormData.card_id,
        coupon_code:
          coupon && coupon?.data?.code ? coupon?.data?.code : undefined,
      },
      service_type_id: Number(serviceTypeId),
      service_type_solicitation_question_answers: serviceFormData.questions.map(
        (question) =>
          question.free_text
            ? {
                question_id: question.question_id,
                value: question.value_id || "-",
                value_id: question?.answers[0]?.id || "",
              }
            : {
                question_id: question.question_id,
                value_id: question.value_id,
              }
      ),
      clientgetninjas_id: serviceFormData.clientgetninjas_id,
    };

    const formDataToSubmit = new FormData();

    formDataToSubmit.append("data", JSON.stringify(dataToSubmit));

    if (!product?.model) {
      setHandleNotificationModal((prevState) => ({
        ...prevState,
        isOpen: true,
        type: "error",
        title: "Pesquise por um móvel",
      }));
      setIsLoading(false);
      return;
    }

    if (!productImage.file) {
      setHandleNotificationModal((prevState) => ({
        ...prevState,
        isOpen: true,
        type: "error",
        title: "Faça upload de uma imagem",
      }));
      setIsLoading(false);
      return;
    }

    if (!serviceFormData.clientgetninjas_id) {
      setHandleNotificationModal((prevState) => ({
        ...prevState,
        isOpen: true,
        type: 'error',
        title: 'Insira as informações do cliente get ninjas',
      }));
      setIsLoading(false);
      return;
    }

    formDataToSubmit.append("image", productImage.file);

    try {
      const response = await api.post(
        "/service-solicitations",
        formDataToSubmit,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      onRequestSolicitation({
        serviceType,
        id: response.data.id,
        date: response.data.date,
        price: totalPrice,
        coupon: coupon?.data,
        product,
      });
    } catch (error: any) {
      setHandleNotificationModal((prevState) => ({
        ...prevState,
        isOpen: true,
        type: "error",
        title: error?.response ? error?.response.data.message : error?.message,
      }));
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  const selectedValue = (questionId: string) => {
    let valueId = "";

    switch (questionId) {
      case "d3cf5afc-face-4286-a9ff-0bb911bdd0bf":
        valueId = "01ad6234-cf8d-4978-840c-7ea13fb9a38b";
        break;
      // case '3eeb081e-af15-4e80-a003-858c8558d940':
      //   valueId = 'b188f820-a0c7-4061-bec2-2d18691d61fc';
      //   break;
      case 'bd9a5be3-333e-4a62-ab08-8fbc24c67faf':
        valueId = '30c89a35-8066-4c03-84a8-469e6548ade9';
        break;
      case "3bbd4a04-4852-44a7-aa92-ab4eb5ae36ea":
        valueId = "116527f4-6832-40e6-a53e-bf8b862197db";
        break;
      // case '95334764-ec6b-4656-ad3a-49d631b70ba8':
      //   valueId = '3e9c3858-d1e5-49c4-82d2-c63264abb88b';
      //   break;
      // case '82163c49-bc93-445b-857c-90b5c077c258':
      //   valueId = '1645f3da-3aa5-4ee9-8c7e-c6d0bd4cb678';
      //   break;
      case 'b4a2e0de-cccf-42ee-a146-258477e68efc':
        valueId = '9823d09a-ed05-4469-9196-29ddd86ef81a';
        break;
      default:
        valueId = '';
        break;
    }
    return valueId;
  };

  const selectedOrder = (questionId: string) => {
    let order;

    switch (questionId) {
      case '82677346-27ba-4b45-8cae-d8ee1b07b831':
        order = 2;
        break;
      case 'd3cf5afc-face-4286-a9ff-0bb911bdd0bf':
        order = 3;
        break;
      case '3eeb081e-af15-4e80-a003-858c8558d940':
        order = 0;
        break;
      case 'bd9a5be3-333e-4a62-ab08-8fbc24c67faf':
        order = 4;
        break;
      case '3bbd4a04-4852-44a7-aa92-ab4eb5ae36ea':
        order = 5;
        break;
      case '95334764-ec6b-4656-ad3a-49d631b70ba8':
        order = 1;
        break;
      case '82163c49-bc93-445b-857c-90b5c077c258':
        order = 2;
        break;
      case 'b4a2e0de-cccf-42ee-a146-258477e68efc':
        order = 6;
        break;
      default:
        order = 7;
        break;
    }
    return order;
  };

  const selectedUseInput = (questionId: string) => {
    let useInput = 1;

    switch (questionId) {
      case 'd3cf5afc-face-4286-a9ff-0bb911bdd0bf':
        useInput = 0;
        break;
      case 'bd9a5be3-333e-4a62-ab08-8fbc24c67faf':
        useInput = 0;
        break;
      case '3bbd4a04-4852-44a7-aa92-ab4eb5ae36ea':
        useInput = 0;
        break;
      case 'b4a2e0de-cccf-42ee-a146-258477e68efc':
        useInput = 0;
        break;
      default:
        useInput = 1;
        break;
    }
    return useInput;
  };

  const getServiceType = async () => {
    try {
      const response = await api.get<IServiceType>(
        `/service-types/${serviceTypeId}`
      );
      setServiceType(response.data);
      setServiceFormData((prevState) => ({
        ...prevState,
        questions: response.data.service_type_solicitation_questions.map(
          (question) => ({
            question_id: question.id,
            value_id: selectedValue(question.id),
            answer_price: '',
            value: '',
            order: selectedOrder(question.id),
            free_text: question.free_text,
            answers: question.service_type_solicitation_question_has_values,
            useInput: selectedUseInput(question.id),
          }),
        ),
      }));
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || error?.message || "Algo deu errado"
      );
    }
  };

  const ensureUserInfo = async (
    cardId = "",
    addressId = "",
    addressStreet = "",
    addressNumber = "",
    addressNeighborhood = "",
    cgnId = "",
    cgnName = "",
    cgnEmail = "",
    cgnTelephone = ""
  ) => {
    try {
      // const addressesResponse = await api.get<{
      //   results: IUserInfo["addresses"];
      // }>("/addresses");
      const cardsResponse = await api.get<{ results: IUserInfo["cards"] }>(
        "/cards"
      );
      const mainCard = cardsResponse.data.results.find(
        (card) => card.is_preferential
      );
      // const mainAddress = addressesResponse.data.results.find(
      //   (address) => address.main
      // );
      setUserInfo({
        address_id: addressId,
        address_street: addressStreet,
        address_number: addressNumber,
        address_neighborhood: addressNeighborhood,
        cards: cardsResponse.data.results,
        clientgetninjas_id: cgnId,
        clientgetninjas_name: cgnName,
        clientgetninjas_email: cgnEmail,
        clientgetninjas_telephone: cgnTelephone,
      });
      setServiceFormData((prevState) => ({
        ...prevState,
        card_id: cardId || mainCard?.id || "",
        address_id: addressId,
        address_street: addressStreet,
        address_number: addressNumber,
        address_neighborhood: addressNeighborhood,
        clientgetninjas_id: cgnId,
        clientgetninjas_name: cgnName,
        clientgetninjas_email: cgnEmail,
        clientgetninjas_telephone: cgnTelephone,
      }));
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || error?.message || "Algo deu errado"
      );
    }
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = event.target;

    setServiceFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCloseCardModal = (id: string) => {
    if (id) {
      setServiceFormData((prevState) => ({
        ...prevState,
        card_id: id,
      }));
      ensureUserInfo(id, serviceFormData.address_id);
    }
    setIsAddCardModalOpen(false);
  };

  const handleOpenAddCardModal = () => {
    setIsAddCardModalOpen(true);
  };

  const handleOpenAddressModal = () => {
    setIsAddressModalOpen(true);
  };

  const handleCloseAddressModal = (
    addressId: string,
    addressStreet: string,
    addressNumber: string,
    addressNeighborhood: string
  ) => {
    if (addressId) {
      setServiceFormData((prevState) => ({
        ...prevState,
        address_id: addressId,
        address_street: addressNumber,
        address_number: addressStreet,
        address_neighborhood: addressNeighborhood,
      }));
      ensureUserInfo(
        serviceFormData.card_id,
        addressId,
        addressStreet,
        addressNumber,
        addressNeighborhood,
        serviceFormData.clientgetninjas_id,
        serviceFormData.clientgetninjas_name,
        serviceFormData.clientgetninjas_email,
        serviceFormData.clientgetninjas_telephone,
      );
    }
    setIsAddressModalOpen(false);
  };

  const handleOpenClientGetNinjasModal = () => {
    setIsClientGetNinjasModalOpen(true);
  };

  const handleCloseCgnModal = (
    cgnId: string,
    cgnName: string,
    cgnEmail: string,
    cgnTelephone: string
  ) => {
    if (cgnId) {
      setServiceFormData((prevState) => ({
        ...prevState,
        clientgetninjas_id: cgnId,
        clientgetninjas_name: cgnName,
        clientgetninjas_email: cgnEmail,
        clientgetninjas_telephone: cgnTelephone,
      }));
      ensureUserInfo(
        serviceFormData.card_id,
        serviceFormData.address_id,
        serviceFormData.address_street,
        serviceFormData.address_number,
        serviceFormData.address_neighborhood,
        cgnId,
        cgnName,
        cgnEmail,
        cgnTelephone
      );
    }
    setIsClientGetNinjasModalOpen(false);
  };

  const handleMonthChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const month = Number(e.target.value);
    const newDate = new Date(appointmentDate);
    newDate.setMonth(month);

    const price = calculatePrice(newDate);
    setTotalPrice(price);

    setAppointmentDate(newDate);
  };

  const handleDayChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const day = Number(e.target.value);

    const newDate = new Date(appointmentDate);
    newDate.setDate(day);

    const price = calculatePrice(newDate);
    setTotalPrice(price);
    setAppointmentDate(newDate);
  };

  const handleTimeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const hour = Number(e.target.value.split(":")[0]);
    const minute = Number(e.target.value.split(":")[1]);

    const newDate = new Date(appointmentDate);
    newDate.setHours(hour);
    newDate.setMinutes(minute);

    const price = calculatePrice(newDate);
    setTotalPrice(price);

    setAppointmentDate(newDate);
  };

  const calculatePrice = (date: Date, productConfirm?: IProduct, updateFormData?: IServiceFormData): number => {
    let price = product ? product.product_value : productConfirm ? productConfirm.product_value : 0;

    if (calculateDifferenceInHours(date) <= 12) {
      price *= 1.15;
    }

    if (updateFormData) {
        updateFormData.questions.forEach((question) => {
            const aswerPrice = question.answers.find(
                (a) => a.id === question.value_id
              )?.price || "0";
            if (aswerPrice !== '' && aswerPrice !== '0') {
            price *= (Number(aswerPrice) / 100) + 1;
            }
        });
    } else {
        serviceFormData.questions.forEach((question) => {
            const aswerPrice = question.answers.find(
                (a) => a.id === question.value_id
              )?.price || "0";
            if (aswerPrice !== '' && aswerPrice !== '0') {
            price *= (Number(aswerPrice) / 100) + 1;
            }
        });
    }
    return parseFloat(price.toFixed(2));
  };

  const calculateDifferenceInHours = (date: Date): number => {
    const currentDate = new Date();

    const differenceInMilliseconds = Math.abs(date.getTime() - currentDate.getTime());
    const differenceInHours = differenceInMilliseconds / (1000 * 60 * 60);
    return differenceInHours;
  };

  const handleAnswerQuestion = (
    questionId: string,
    answerId: string,
    answerValue: string
  ) => {
    setServiceFormData((prevState) => {
        const updatedState = {
          ...prevState,
          questions: prevState.questions.map((question) =>
            question.question_id === questionId
              ? {
                  ...question,
                  value_id: answerId,
                  value: answerId,
                  answer_price: answerValue,
                }
              : question
          ),
        };
        const price = calculatePrice(appointmentDate, undefined, updatedState);
        setTotalPrice(price);
        return updatedState;
      });
    };

  const handleSetCurrentTime = () => {
    const currentDate = new Date();

    if (currentDate.getMinutes() > 30) {
      currentDate.setHours(currentDate.getHours() + 1);
      currentDate.setMinutes(0);
    } else {
      currentDate.setMinutes(30);
    }
    setAppointmentDate(currentDate);
  };

  const handleOpenEditCardModal = () => {
    setIsEditCardModalOpen(true);
  };

  const handleSelectCard = (id: string) => {
    setServiceFormData((prevState) => ({
      ...prevState,
      card_id: id,
    }));
    setIsEditCardModalOpen(false);
  };

  const handleCloseEditCardModal = () => {
    setIsEditCardModalOpen(false);
  };

  const handleOpenPaymentInformationModal = () => {
    setIsPaymentInformationModalOpen(true);
  };

  const handleAddCoupon = async () => {
    try {
      const response = await api.get<ICoupon>(
        `/family-coupons/coupons/${serviceFormData.coupon_code}`
      );
      setCoupon((prevState) => ({
        error: "",
        data: response.data,
      }));
    } catch (error: any) {
      setCoupon({
        error: "Cupom inválido",
        data: undefined,
      });
    }
  };

  const handleConfirmProductSearch = (productToConfirm: IProduct) => {
    setProduct(productToConfirm);
    const price = calculatePrice(appointmentDate, productToConfirm);
    setTotalPrice(price);
    setHandleSearchProductModal((prevState) => ({
      ...prevState,
      isOpen: false,
    }));
  };

  const handleOpenSearchProductModal = () => {
    setHandleSearchProductModal((prevState) => ({
      ...prevState,
      isOpen: true,
      onConfirm: handleConfirmProductSearch,
    }));
  };

  const handleProductImageUpload = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { files } = event.target;

    if (!files || !files.length) return;

    const file = files[0];

    setProductImage({
      file,
      url: URL.createObjectURL(file),
    });
  };

  const calculateDiscount = (price: number) => {
    if (!coupon?.data) return price;

    const { discount_type, amount } = coupon.data.family_coupon;

    if (discount_type === 1) {
      return price * (1 - amount / 100);
    }
    if (discount_type === 2) {
      return price - amount;
    }
    return price;
  };

  return (
    <>
      {isSplashOpen ? (
        <ServiceSplashScreen
          serviceType={serviceType}
          onClose={() => setIsSplashOpen(false)}
        />
      ) : (
        <>
          <StyledHeader>
            <div>
              <Hexagon customSize="4.25rem">
                <CategoryIcon text={serviceType?.name || ""} />
              </Hexagon>
              <h2>{serviceType?.name || ""}</h2>
            </div>
          </StyledHeader>
          <Container>
            <GoBackButton to="/">
              <ArrowLeftIcon />
            </GoBackButton>
            <InformationContainer>
              <h3>
                Por favor, responda as perguntas abaixo para darmos início a sua
                solicitação. É super simples e rápido! vamos lá?
              </h3>
              <p>
                Se precisar de ajuda, nossa equipe estará disponível para te
                auxiliar na aba{" "}
                <NavLink to="/suporte">
                  <strong>Suporte</strong>
                </NavLink>{" "}
                da plataforma.
              </p>
            </InformationContainer>
            <StyledForm onSubmit={handleSubmit}>
              <div>
                <label
                  className="address-select-label"
                  htmlFor="address-select"
                >
                  <MarkerIcon />
                  Qual é o local da instalação?
                </label>
                <div className="request-service__address-wrapper">
                  <button type="button" onClick={handleOpenAddressModal}>
                    <CircleAdd />
                  </button>
                  <Select
                    id="address-select"
                    name="address_id"
                    options={
                      userInfo && userInfo.address_id !== ""
                        ? getAddressOptions(
                            userInfo.address_id,
                            userInfo.address_street,
                            userInfo.address_number,
                            userInfo.address_neighborhood,
                          )
                        : []
                    }
                    value={serviceFormData.address_id}
                    onChange={handleInputChange}
                    customFontSize="1.25rem"
                    useInput={1}
                  />
                </div>
              </div>
              <div>
                <label
                  className="address-select-label"
                  htmlFor="address-select"
                >
                  <GetNinjasIcon />
                  Qual é o cliente GetNinjas?
                </label>
                <div className="request-service__address-wrapper">
                  <button
                    type="button"
                    onClick={handleOpenClientGetNinjasModal}
                  >
                    <CircleAdd />
                  </button>
                  <Select
                    id="clientgetninja-select"
                    name="clientgetninja_id"
                    options={
                      userInfo && userInfo.clientgetninjas_id !== ""
                        ? getClientGetNinjasOptions(
                            userInfo.clientgetninjas_id,
                            userInfo.clientgetninjas_name,
                            userInfo.clientgetninjas_email
                          )
                        : []
                    }
                    value={serviceFormData.clientgetninjas_id}
                    placeholder="Adicione um cliente"
                    onChange={handleInputChange}
                    customFontSize="1.25rem"
                    useInput={1}
                  />
                </div>
              </div>
              <div>
                <label>
                  <ServiceQuestionIcon serviceName={serviceType?.name || ""} />
                  Pesquise o seu produto
                </label>
                <RequestServiceButton
                  type="button"
                  onClick={handleOpenSearchProductModal}
                >
                  Pesquisar
                </RequestServiceButton>
              </div>
              {product && (
                <ProductContainer>
                  <div>
                    <div>
                      <h2>{product.model}</h2>
                      <p>
                        Marca:
                        {product.brand}
                      </p>
                      <p>
                        Tipo:
                        {product.type}
                      </p>
                    </div>
                  </div>
                </ProductContainer>
              )}
              <div>
                <label>
                  <ServiceQuestionIcon serviceName={serviceType?.name || ""} />
                  Envie uma foto do produto ou etiqueta
                </label>
                <RequestServiceButton
                  as="label"
                  className="request-service__photo-upload-label"
                >
                  Enviar
                  <input
                    type="file"
                    value=""
                    onChange={handleProductImageUpload}
                    accept="image/*"
                  />
                </RequestServiceButton>
              </div>
              {productImage.url && (
                <img
                  className="request-service__product-photo"
                  src={productImage.url}
                  alt={product?.model}
                />
              )}
              {serviceType?.service_type_solicitation_questions
                .sort((a, b) => {
                  const questionA = serviceFormData.questions.find((q) => q.question_id === a.id);
                  const questionB = serviceFormData.questions.find((q) => q.question_id === b.id);
                  if (questionA && questionB) {
                    return questionA.order - questionB.order;
                  }
                  return 0;
                })
                .map((question) => (
                  <div key={question.id}>
                    <label
                      htmlFor={question.id}
                      style={{
                        color: serviceFormData.questions.find((q) => q.question_id === question.id)?.useInput === 0
                          ? '#5f5f5f'
                          : '#241DCD',
                      }}
                    >
                      <ServiceQuestionIcon
                        serviceName={serviceType?.name}
                        question={question}
                        useInput={
                          serviceFormData.questions.find((q) => q.question_id === question.id)?.useInput
                        }
                      />
                      {question.question}
                    </label>
                    {question.free_text ? (
                      <input
                        type="text"
                        className="request-service__input"
                        name={question.id}
                        id={question.id}
                        value={
                          serviceFormData.questions.find(
                            (q) => q.question_id === question.id
                          )?.value_id
                        }
                        onChange={(e) =>
                          handleAnswerQuestion(question.id, e.target.value, "0")
                        }
                      />
                    ) : (
                      <Select
                        id={question.id}
                        options={getOptionsFromQuestionAnswer(
                          question.service_type_solicitation_question_has_values
                        )}
                        onChange={(e) => {
                          handleAnswerQuestion(
                            e.target.id,
                            e.target.value,
                            question.service_type_solicitation_question_has_values.find(
                              (a) => a.id === e.target.value
                            )?.price || "0"
                          );
                        }}
                        value={
                          serviceFormData.questions.find(
                            (q) => q.question_id === question.id
                          )?.value_id
                        }
                        customFontSize="1.25rem"
                        useInput={
                          serviceFormData.questions.find((q) => q.question_id === question.id)?.useInput
                        }
                      />
                    )}
                  </div>
                ))}
              {/* <div>

                <label>
                  <CalendarIcon />
                  Agende o dia e horário para seu atendimento ou aperte o botão
                  abaixo caso precise de atendimento agora:
                </label>
                <RequestServiceButton
                  type="button"
                  onClick={handleSetCurrentTime}
                >
                  Preciso agora!
                </RequestServiceButton>
              </div> */}

              <div>
                <label htmlFor="">Agendar para:</label>
                <AppointmentDateContainer>
                  <Select
                    options={daysOptions}
                    placeholder="Dia"
                    onChange={handleDayChange}
                    value={selectedDay}
                    customFontSize="1.25rem"
                  />
                  <Select
                    options={MonthsOptions}
                    placeholder="Mês"
                    onChange={handleMonthChange}
                    value={selectedMonth}
                    customFontSize="1.25rem"
                  />
                  <Select
                    options={timeOptions}
                    placeholder="Horário"
                    onChange={handleTimeChange}
                    value={selectedTime}
                    customFontSize="1.25rem"
                  />
                </AppointmentDateContainer>
              </div>

              <CouponContainer>
                <label htmlFor="coupon-code">
                  <CouponIcon />
                  Cupom de desconto
                </label>
                <div className="code-input-container">
                  <input
                    id="coupon-code"
                    type="text"
                    name="coupon_code"
                    placeholder="Código do cupom"
                    value={serviceFormData.coupon_code}
                    onChange={handleInputChange}
                  />
                  <button
                    type="button"
                    disabled={!serviceFormData.coupon_code.length}
                    onClick={handleAddCoupon}
                  >
                    Adicionar
                  </button>
                </div>
                {coupon?.error && <p>{coupon?.error}</p>}
              </CouponContainer>

              <PaymentContainer>
                <div className="request-service__value-container">
                  <span>Valor estimado:</span>
                  <div>
                    <span>
                      {formatCurrency(
                        coupon?.data
                          ? calculateDiscount(totalPrice)
                          : totalPrice
                      )}
                    </span>
                    {coupon?.data && <span>{formatCurrency(totalPrice)}</span>}
                  </div>
                </div>
                <div className="request-service__add-payment-container">
                  {selectedCard ? (
                    <div>
                      <SelectedCard
                        card={selectedCard}
                        customClassName="request-service__card-information-container"
                      />
                      <button
                        type="button"
                        className="request-service__add-card-button"
                        onClick={handleOpenEditCardModal}
                      >
                        Alterar meio de pagamento
                      </button>
                    </div>
                  ) : (
                    <button
                      type="button"
                      className="request-service__add-card-button"
                      onClick={handleOpenAddCardModal}
                    >
                      <CircleAdd />
                      Adicionar forma de pagamento
                    </button>
                  )}
                  <RequestServiceButton type="submit" disabled={isLoading}>
                    {isLoading ? <LoadingIcon /> : "Solicitar atendimento"}
                  </RequestServiceButton>
                </div>
              </PaymentContainer>
              <PaymentQuestion>
                <button
                  type="button"
                  className="payment-information-button"
                  onClick={handleOpenPaymentInformationModal}
                >
                  <QuestionIcon />
                </button>
                Como funciona o cálculo do valor do pagamento
              </PaymentQuestion>
            </StyledForm>
          </Container>
          <AddCardModal
            isOpen={isAddCardModalOpen}
            onClose={handleCloseCardModal}
          />
          <NotificationModal
            isOpen={handleNotificationModal.isOpen}
            onClose={handleNotificationModal.onClose}
            title={handleNotificationModal.title}
            type={handleNotificationModal.type}
          />
          <AddressModal
            isOpen={isAddressModalOpen}
            onClose={handleCloseAddressModal}
          />
          <ClientGetNinjasModal
            isOpen={isClientGetNinjasModalOpen}
            onClose={handleCloseCgnModal}
          />
          <EditMainCardModal
            isOpen={isEditCardModalOpen}
            onClose={handleCloseEditCardModal}
            onSelect={handleSelectCard}
            cards={userInfo?.cards as IUserCard[]}
          />
          <PaymentInformationModal
            isOpen={isPaymentInformationModalOpen}
            onClose={() => setIsPaymentInformationModalOpen(false)}
          />
          <SearchProductModal
            isOpen={handleSearchProductModal.isOpen}
            onClose={handleSearchProductModal.onClose}
            onConfirm={handleSearchProductModal.onConfirm}
            serviceTypeId={serviceTypeId}
          />
          {isSearchingProviderOpen && (
            <SearchingProvider
              serviceName={requestedSolicitation?.serviceType?.name || ""}
              serviceSolicitationId={requestedSolicitation?.id || ""}
              clearNewAppointment={clearNewAppointment}
            />
          )}
        </>
      )}
    </>
  );
}
