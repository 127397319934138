import { useState, memo } from 'react';

import styled from 'styled-components';
import Modal from 'react-modal';

import { toast } from 'react-toastify';
import { ReactComponent as CheckOutlineIcon } from '../../assets/images/check-outline.svg';
import { ReactComponent as SearchIcon } from '../../assets/images/product-search.svg';
import { api } from '../../services/api';
import { IProduct } from '../../types';

interface ISearchProductModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (productToConfirm: IProduct) => void;
  serviceTypeId: number | string;
}

export default function SearchProductModal({
  isOpen,
  onClose,
  onConfirm,
  serviceTypeId,
}: ISearchProductModalProps) {
  const [products, setProducts] = useState<IProduct[]>([]);
  const [search, setSearch] = useState('');

  const handleInputChange = async ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(value);

    if (value.length >= 3) {
      await handleSearch(); // Aqui você precisa invocar a função com parênteses
    }
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  const handleSearch = async () => {
    try {
      const response = await api.get<{results: IProduct[], limit: number, total: number, page: number}>('/products', {
        params: {
          model: search,
          service_type_id: serviceTypeId,
          limit: 50,
          page: 1,
        },
      });
      setProducts(response.data.results.map((product) => ({
        ...product,
        id: product.id.toString(),
      })));
    } catch (error: any) {
      toast.error(
        error?.response?.data?.message || error?.message || 'Algo deu errado',
      );
    }
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onClose}
      className="react-modal__content"
      overlayClassName="react-modal__overlay react-modal__background"
    >
      <Container>
        <button type="button" className="close-button" onClick={handleClose}>X</button>
        <Content>
          <h1>
            Pesquise seu produto
          </h1>
          <SearchBox as="form" onSubmit={handleSubmit}>
            <input type="search" placeholder="Informe o modelo" value={search} onChange={handleInputChange} />
            <button type="button">
              <SearchIcon />
            </button>
          </SearchBox>
          <ListWrapper>
            <List>
              {products.map((product) => (
                <MemoizedProduct
                  key={product.id}
                  product={product}
                  onConfirm={onConfirm}
                />
              ))}
            </List>
          </ListWrapper>
        </Content>
      </Container>
    </Modal>
  );
}

function Product({ product, onConfirm }: {
    product: IProduct,
    onConfirm: (productToConfirm: IProduct) => void
  }) {
  return (
    <ProductContainer>
      <div>
        <p className="product__model-name">
          {product.model}
        </p>
        <p>
          Marca:
          {' '}
          {product.brand}
        </p>
        <p>
          Tipo:
          {' '}
          {product.type}
        </p>
      </div>
      <button type="button" onClick={() => onConfirm(product)}>
        <CheckOutlineIcon />
      </button>
    </ProductContainer>
  );
}

const MemoizedProduct = memo(
  Product, (prevProps, nextProps) => prevProps.product.id === nextProps.product.id,
);

const Container = styled.div`
  width: 98vw;
  margin: 0 auto;
  max-width: 35rem;
  min-height: 30rem;
  max-height: 80vh;
  position: relative;

  border-radius: 2rem;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.16);
  background-color: #fff;
  padding: 2rem;
  padding-right: 1.5rem;

  .close-button {
    background-color: transparent;
    border: none;
    position: absolute;
    top: 1rem;
    right: 1rem;

    color: black;
    font-size: 1.25rem;
    font-weight: 700;
  }
`;

const Content = styled.div`

  > * + * {
    margin-top: 2rem;
  }

  h1 {
    color: #241DCD;
    font-size: 1.25rem;
    font-weight: 700;
    width: 100%;
    text-align: center;
  }
`;

const SearchBox = styled.div`
  min-height: 3rem;
  border-radius: 1.5rem;

  padding: 0 1.25rem;

  margin-right: 1rem;

  position: relative;

  display: flex;
  align-items: center;

  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);

  &:focus-within {
    outline: 2px solid #000;
  }

  input {
    border: none;
    background: transparent;
    outline: none;

    font-size: 1rem;

    flex: 1;

    line-height: 3;

    &::placeholder {
      color: #B8B5B5;
    }
  }

  button {
    background: transparent;
    border: none;

    svg {
      width: 1rem;
      height: 1rem;
      fill: #241DCD;
      vertical-align: middle;
    }
  }
`;

const ListWrapper = styled.div`
  max-height: max(55vh, 20rem);
  overflow: auto;

  scrollbar-width: thin;
  scrollbar-color: #241DCD #CFCFFF;

  padding-right: 1rem;

  &::-webkit-scrollbar {
    width: 0.4rem;
    height: 0.4rem;
  }

  &::-webkit-scrollbar-track {
    border-radius: 1.25rem;
    background-color: #CFCFFF;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 1.25rem;
    background-color: #241DCD;
  }

  @media (max-height: 560px) {
    max-height: 30vh;
  }
`;

const List = styled.div``;

const ProductContainer = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 1rem;

  border-top: 1px solid #2C2C2C15;

  color: rgba(44, 44, 44, 0.69);

  font-size: 1rem;

  line-height: 1.15;

  .product__model-name {
    font-size: 1.125rem;
    margin-bottom: 0.75rem;
  }

  button {
    background: transparent;
    border: none;

    align-self: flex-end;

    svg {
      overflow: visible;
      vertical-align: middle;
      fill:#fff;
    }

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.5);
    }
  }
`;
