import Appliances from '../../assets/images/service-categories/appliances.png';
import Furniture from '../../assets/images/service-categories/furniture.png';
import Laptop from '../../assets/images/service-categories/laptop.png';
import Electrician from '../../assets/images/service-categories/electrician.png';
import Locksmith from '../../assets/images/service-categories/locksmith.png';
import Plumber from '../../assets/images/service-categories/plumber.png';
import Painter from '../../assets/images/service-categories/painter.png';
import Tow from '../../assets/images/service-categories/tow.png';

interface ICategoryIconProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  text: string;
}

const getCategoryIcon = (category: string) => {
  const lowerCase = category.toLowerCase();
  switch (true) {
    case lowerCase.includes('montador'):
      return Furniture;
    case lowerCase.includes('eletricista'):
      return Electrician;
    case lowerCase.includes('encanador'):
      return Plumber;
    case lowerCase.includes('pintor'):
      return Painter;
    case lowerCase.includes('guincho'):
      return Tow;
    case lowerCase.includes('chaveiro'):
      return Locksmith;
    case lowerCase.includes('informática'):
      return Laptop;
    case lowerCase.includes('instalador'):
      return Appliances;
    default:
      return Furniture;
  }
};

export default function CategoryIcon({ text, ...rest } : ICategoryIconProps) {
  const categoryIcon = getCategoryIcon(text);

  return (
    <img src={categoryIcon} alt={text} {...rest} />
  );
}
